import styled, { css } from "styled-components"
import { media } from "../../styles/media"

export const StyledWrap = styled.div`
  width: 80%;
  margin: 100px auto 100px;
  ${() =>
    media.sp(css`
      margin: 50px auto;
    `)}
`

export const StyledTitleWrapper = styled.div`
  /* display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: space-between; */
  margin-bottom: 30px;
  .title {
    font-size: 1.62em;
  }
  .pubDate {
    color: gray;
    font-size: 0.9em;
    padding-top: 0.62em;
    display: inline-block;
  }
  ${() =>
    media.tablet(css`
      flex-flow: column nowrap;
      .title {
        width: 100%;
      }
    `)}
`

export const StyledContentsWrapper = styled.div`
  padding: 30px 0;
  p {
    padding: 0.62em 0;
    line-height: 1.62em;
  }
`

export const StyledLinkWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
  padding: 50px 0;
  margin-top: 30px;

  ${() =>
    media.tablet(css`
      margin: 20px auto;
      width: 100%;
    `)}
  ${() =>
    media.sp(css`
      flex-flow: column nowrap;
      align-items: center;
      justify-content: space-around;
      a {
        &:last-child {
          margin-bottom: 0;
        }
      }
    `)}
`
