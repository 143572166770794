import React from "react"
import { BsSkipBackward, BsArrowRight, BsBoxArrowRight } from "react-icons/bs"
import { AiOutlineFilePdf } from "react-icons/ai"
import { IconContext } from "react-icons"
import styled from "styled-components"

const StyledFileSize = styled.span`
  padding-left: 0.2rem;
  font-size: 0.8rem;
`

export const IconBack = () => (
  <IconContext.Provider
    value={{ size: "1rem", color: "white", style: { marginRight: "0.62em" } }}
  >
    <BsSkipBackward />
  </IconContext.Provider>
)

export const IconRight = () => (
  <IconContext.Provider
    value={{ size: "1rem", color: "white", style: { marginRight: "0.62em" } }}
  >
    <BsArrowRight />
  </IconContext.Provider>
)
export const IconLink = () => (
  <IconContext.Provider value={{ size: "1rem", color: "white" }}>
    <BsBoxArrowRight />
  </IconContext.Provider>
)

export const PdfIcon = ({ fileSize = null }) => {
  return (
    <IconContext.Provider
      value={{ size: "1rem", color: "white", style: { marginLeft: "0.62em" } }}
    >
      <AiOutlineFilePdf />
      {fileSize && <StyledFileSize>{fileSize}</StyledFileSize>}
    </IconContext.Provider>
  )
}
