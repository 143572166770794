import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"

const flexAttr = css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0.62em 1.62em;
  border-radius: 0.32em;
  transition: 0.3s;
  &:hover {
    background: black;
  }

  ${() =>
    media.sp(css`
      width: 60%;
      margin-bottom: 20px;
      }
    `)}
`

const StyledInnerLink = styled(Link)`
  ${flexAttr}
  background-color: ${props => props.bgcolor};
`

const StyledOuterLink = styled.a`
  ${flexAttr}
  background-color: ${props => props.bgcolor}
`

export const InnerLinkButton = ({ to, bgcolor = "gray", children }) => (
  <StyledInnerLink to={to} bgcolor={bgcolor}>
    {children}
  </StyledInnerLink>
)

export const OuterLinkButton = ({ to, bgcolor = "gray", children }) => (
  <StyledOuterLink href={to} bgcolor={bgcolor} target="_blank" noopener>
    {children}
  </StyledOuterLink>
)
